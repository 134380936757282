import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import VueToast from 'vue-toast-notification'
import VueI18n from 'vue-i18n'
import VueSweetalert2 from 'vue-sweetalert2'
import VueOffline from 'vue-offline'
// import VueMce from 'vue-mce'

// Icons
import SvgIcon from '@/components/svgIcon.vue'
import IconCheck from '@/components/icons/iconCheck.vue'
import IconClock from '@/components/icons/iconClock.vue'
import IconTrash from '@/components/icons/iconTrash.vue'

// Import any of available themes
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import '@fortawesome/fontawesome-free/css/regular.css'
// import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-toast-notification/dist/theme-default.css'
// import 'vue-toast-notification/dist/theme-sugar.css'
import 'sweetalert2/dist/sweetalert2.min.css'

import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'

import './registerServiceWorker'

// PRODUCTION
Vue.config.devtools = false
Vue.config.productionTip = false

// DEVELOPMENT
// Vue.config.devtools = true
// Vue.config.productionTip = true

/*
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
} else {
  Vue.config.devtools = false
}
*/

Vue.use(VueI18n)
Vue.use(HighchartsVue)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCookies)
Vue.use(VueToast)
Vue.use(VueSweetalert2)
Vue.use(VueOffline)
// Vue.use(VueMce)

// icons
Vue.component('svg-icon', SvgIcon)
Vue.component('icon-check', IconCheck)
Vue.component('icon-clock', IconClock)
Vue.component('icon-trash', IconTrash)

Vue.prototype.$http = Axios
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token
}

let userLang = navigator.language
// console.log(navigator.language)
userLang = userLang.split('-')[0]
const lang = localStorage.getItem('locale')
// console.log('Language: ' + userLang)
// console.log(lang)
if (lang && userLang !== lang) userLang = lang

const i18n = new VueI18n({
  locale: userLang,
  fallbackLocale: 'sl'
})
// console.log(' I18N ' + i18n.locale)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
