<template>
  <div class="app-modal-background" @click="closeModal()">
    <div :class="(mobile)?'app-modal':'app-modal-float'" style="z-index:14" :style="{ width: width, 'background-color':(!mobile)?'transparent':'white' }" @click.stop="dummyClick">
      <div class="overlay w-100 h-100 p-absolute" style="background:rgb(0,0,0,.2);z-index:20;" v-if="loading">
        <b-spinner style="margin-top:300px;"></b-spinner>
      </div>
      <div class="app-modal-header" v-if="mobile">
        <div class="app-modal-title float-left w-100">
          <slot name="title"></slot>
        </div>
<!--
        <div class="close-button" @click="closeModal()">
          <i
            class="fas fa-times fa-lg app-modal-close"
            title="Abbrechen"
          ></i>
        </div>
-->
        <div class="clearfix"></div>
      </div>
      <div class="app-modal-content" :class="(scroll)?'no-overflow':''"><slot name="content"></slot></div>
      <div class="app-modal-footer" v-if="mobile">
        <div class="app-modal-buttons-left float-left">
          <slot name="buttons-left"></slot>
        </div>
        <div class="app-modal-buttons-right" style="width:100%;">
          <slot name="buttons-right"></slot>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppModal',
  props: {
    width: {
      type: String,
      default: '50%'
    },
    mobile: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    dummyClick () {

    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
<style>
  .no-overflow {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
<style scoped>
.app-modal-background {
  position: fixed;
  z-index: 14;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.app-modal {
  position: fixed;
  top: 40vh;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fefefe;
  margin: 5% auto;
  border-radius: 5px;
  -webkit-box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.53);
  -moz-box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.53);
  box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.53);
}
.app-modal-float {
  background-color: #fefefe;
  margin: 2% auto;
  border-radius: 5px;
  -webkit-box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.53);
  -moz-box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.53);
  box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.53);
}
.app-modal-header {
  position: relative;
  padding: 5px;
  background-color: rgba(255,153,0,.8);
  border-radius: 5px 5px 0 0;
/*  border-bottom: 1px solid rgb(231, 234, 236); */
}
.app-modal-title {
  font-weight: 800;
}
.app-modal-content {
  padding: 10px 12px;
}
.app-modal-footer {
  padding: 10px 12px;
}
.app-modal-buttons-right button {
  margin-left: 10px;
  min-width: 60px;
  font-size: 11px;
}
.app-modal-buttons-left button {
  margin-right: 10px;
}
.app-modal-close {
  cursor: pointer;
}
.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
