import axios from 'axios'
import store from '@/store'

const baseUrl = process.env.VUE_APP_API
// const corsDomain = process.env.VUE_APP_DOMAIN

// const AXIOS = axios.create({
//   baseURL: process.env.VUE_APP_API,
//   timeout: 10000,
//   withCredentials: true
// })
export default {
  // executePostServerCheckUidAction (uidData) {
  //   const authHeader = { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': corsDomain } }
  //   return AXIOS.post('/uid/index.php', uidData, authHeader)
  // },
  // executePostServerPaymentAction (paymentData) {
  //   const authHeader = { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': corsDomain } }
  //   return AXIOS.post('jsoninit.php', paymentData, authHeader)
  // },
  // getPartners (clientId) {
  //   const auth = { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': corsDomain } }
  //   return AXIOS.get('getPartners?ClientID=' + clientId, auth)
  // },
  executePostServerAction (action, data = null, auth = true, multipart = null) {
    action = baseUrl + action + '.php'
    // const authHeader = { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': corsDomain } }
    const authHeader = { }
    // console.log('DATA')
    // console.log(data)
    /*
    if (auth) {
      // Add appAuthentication data!
      data.authtoken = store.state.appAuthToken
      data.clientshortdesignation = store.state.clientShortDesignation
    }
    */
    if (!auth && multipart) {
      // console.log('  MULTIPART')
      authHeader.headers = { 'Content-Type': 'multipart/form-data' } // , 'Authorization': 'Bearer ' + store.getters.token }
    }
    if (auth && !multipart) {
      // console.log('  AUTH')
      authHeader.headers = { Authorization: 'Bearer ' + store.getters.token }
    }
    if (auth && multipart) {
      // if (multipart) {
      // console.log('  AUTH + MULTIPART')
      authHeader.headers = { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + store.getters.token }
    }
    // console.log('Action --> ' + action)
    // console.log(authHeader)
    // console.log('AUTH: ' + auth)
    // axios.defaults.withCredentials = true
    return axios.post(action, data, authHeader, { withCredentials: true })
  }
}
