<template>
  <div class="text-center" :class="[captionPosition, 'silver-button-'+borderColor]" @click="doClick">
    <span v-if="!loading" v-html="caption"> </span>
    <b-spinner v-else small></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'silverButton',
  props: {
    caption: { default: '', type: String },
    captionPosition: { default: 'text-center', type: String },
    disabled: { default: false, type: Boolean },
    color: { default: 'grey', type: String },
    borderColor: { default: 'grey', type: String },
    hoverColor: { default: 'grey', type: String },
    loading: { default: false, type: Boolean }
  },
  data () {
    return {
      selectedItem: null
    }
  },
  methods: {
    doClick () {
      if (this.disabled) return false
      this.$emit('btnClick')
    }
  },
  mounted () {
  },
  computed: {
  }
}
</script>
<style scoped>
  .silver-button-, .silver-button-green, .silver-button-red, .silver-button-grey {
    float: right;
    cursor: pointer;
    padding: 5px 30px;
    border: solid 1px #8EC1DA;
    font-size: 0.9em;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px #FFFFFF, inset 0 -15px #CBE6F2, 0 0 3px #8EC1DA;
    background-color: #DDEEF6;
    color: #555; /* #3985A8; */
    text-shadow: 0 1px #FFFFFF;
    margin-top: -3px;
  }
  .silver-button-green {
    color: darkgreen !important;
    border: solid 1px #05B01E;
  }
  .silver-button-red {
    color: darkred !important;
    border: solid 1px darkred;
  }
  .silver-button-grey {
    color: #333 !important;
    border: solid 1px grey;
  }

  .silver-button-:hover {
    border: solid 1px black;
    box-shadow: inset 0 1px 3px #FFFFFF, inset 0 -15px #CBE6F2, 0 0 3px grey;
      /*#FF8C1C;*/
  }
  .silver-button-green:hover {
    border: 1px solid #05B01E;
    box-shadow: inset 0 1px 3px #FFFFFF, inset 0 -15px #CBE6F2, 0 0 3px #05B01E;
      /*#FF8C1C;*/
  }
  .silver-button-red:hover {
    border: solid 1px darkred;
    box-shadow: inset 0 1px 3px #FFFFFF, inset 0 -15px #CBE6F2, 0 0 3px darkred;
      /*#FF8C1C;*/
  }
  .silver-button-grey:hover {
    border: solid 1px grey;
    box-shadow: inset 0 1px 3px #FFFFFF, inset 0 -15px #CBE6F2, 0 0 3px grey;
      /*#FF8C1C;*/
  }
</style>
