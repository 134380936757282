import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import Login from '../views/Login.vue'
// import Object from '@/components/scada/hiskaMobile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    // component: Login
    component: () => import(/* webpackChunkName: "deq" */ '../views/Login.vue')
  },
  // {
  //   path: '/object',
  //   name: 'Object',
  //   component: Object,
  //   meta: {
  //     requiresAuth: true
  //   }
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   //    component: () => import(/* webpackChunkName: "deqs" */ '../views/Deqs.vue')
  // },
  {
    path: '/messages',
    name: 'Messages',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue')
  },
  {
    path: '/deqs',
    name: 'Deqs',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "controllers" */ '../views/Deqs.vue')
  },
  {
    path: '/objects',
    name: 'Objects',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "objects" */ '../views/Objects.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue')
  },
  {
    path: '/scada',
    name: 'Scada',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "scada" */ '../views/Scada.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSettings.vue')
  },
  {
    path: '/info',
    name: 'Info',
    meta: {
    },
    component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue')
  },
  {
    path: '/splosni-pogoji',
    name: 'SplosniPogoji',
    meta: {
    },
    component: () => import(/* webpackChunkName: "info" */ '../views/SplosniPogoji.vue')
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.loginSuccess) {
    const user = localStorage.getItem('storedUser')
    const pass = localStorage.getItem('storedPass')
    if (user != null && pass != null) {
      store.dispatch('login', { userName: user, userPass: pass })
        .then(() => {
          if (store.state.loginSuccess) {
            localStorage.setItem('storedUser', user)
            localStorage.setItem('storedPass', pass)
          }
          next()
          // const reRoute = localStorage.getItem('storedRoute')
          // // if (reRoute === '/home') reRoute = '/'
          // if (!reRoute) {
          //   next({ path: '/' })
          // } else {
          //   localStorage.removeItem('storedRoute')
          //   next({ path: reRoute })
          // }
        })
        .catch(error => {
          localStorage.removeItem('storedUser')
          localStorage.removeItem('storedPass')
          store.state.loginSuccess = false
          // console.log('Error Response:')
          console.log(error)

          next({ path: '/login' })
        })
    } else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  localStorage.setItem('storedRoute', '/' + to.name.toLowerCase())
  // Complete the animation of the route progress bar.
  // NProgress.done()
})

export default router
