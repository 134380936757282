<template>
  <div class="update-prompt w-100 p-1">
    <div class="update-img" :class="(appWidth<mobileWidth)?'text-center w-100':''"><img :src="require('@/assets/_img/update.gif')" /></div>
    <div class="update-img" :class="(appWidth<mobileWidth)?'text-center w-100 mb-2':''">{{$t('installApp')}}</div>
    <silver-button
      class="mx-1 mt-0" :class="(appWidth<mobileWidth)?'float-right':'w-25'"
      :caption="$t('install')"
      captionPosition="text-center"
      :disabled="false"
      borderColor="green"
      @btnClick="installUpdate"
    />
    <!-- <silver-button class="w-25" :caption="$t('noInstall')" captionPosition="text-center" :disabled="false" @btnClick="dismissPrompt" /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import silverButton from '@/components/silverButton.vue'
export default {
  name: 'update-prompt',
  components: {
    silverButton
  },
  data () {
    return {
    }
  },
  methods: {
    installUpdate () {
      this.$emit('refresh')
    },
    dismissPrompt () {
      this.$emit('cancel')
    }
  },
  computed: {
    ...mapState({
      mobileWidth: state => state.app_mobile_width,
      appWidth: state => state.appWidth
    })
  }
}
</script>
<style>
  .update-prompt {
    z-index: 15;
/*     border: grey; */
    position: fixed;
    background: white;
    font-weight: bold;
    border-bottom: solid 1px #d3d3d3;
  }
  .update-img  {
    margin-top:3px;float:left; margin-left: 10px;
  }
  .update-img img {
    width:30px;
  }
</style>
<i18n>
  {
    "sl": {
      "installApp": "Na voljo je posodobitev.",
      "install": "Posodobi",
      "noInstall": "Ne, hvala"
    },
    "en": {
      "installApp": "New content is available.",
      "install": "Refresh",
      "noInstall": "No, thanks"
    },
    "de": {
      "installApp": "Neue Inhalte sind verfügbar.",
      "install": "Aktualisieren",
      "noInstall": "Nein, Danke"
    }
  }
</i18n>
