/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      // Notification.requestPermission(status => {
      //   console.log('Status:', status)
      // })
    },
    registered (registration) {
      console.log('Service worker has been registered.')
      // B ------------ REFRESH PWA FEATURE ------------
      setInterval(() => {
        registration.update()
      }, 5000) // every 5 seconds
      // E ------------ REFRESH PWA FEATURE ------------
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (e) {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

  let refreshing
  self.addEventListener('controllerchange', function () {
    if (refreshing) return
    window.location.reload()
    refreshing = true
  })
}
