<template>
<!--    <canvas v-if="activeBackground" id="canvas" style="z-index:-1000;position:fixed;top:0;left:0"></canvas>-->
  <div id="app" >
    <update-prompt v-if="updateExists"
      @refresh="refreshApp"
      @cancel="cancelRefresh"
    />
    <pwa-prompt />
    <nav v-if="!hideAppHeader" class="navbar navbar-expand p-1 mb-3" :class="(loggedIn && appWidth<mobileWidth)?'nav-dark':''" :style="(!loggedIn && appWidth<mobileWidth)?'background-color:transparent;position:relative !important;':''">
      <!-- <transition name="fade" mode="out-in">
        <menu-x v-if="(appWidth<mobileWidth)?menuVisible:true" @select="menuVisible=false"/>
      </transition> -->
      <menu-language />
    </nav>

    <transition v-if="appMode==='normal'" name=" fade" mode="out-in">
      <!-- <keep-alive include="Objects"> -->
        <router-view :key="$route.fullPath" style="padding-bottom:40px !important;" :style="(!hideAppHeader)?((!loggedIn && appWidth<mobileWidth)?'padding-top:20px !important;':'padding-top:70px !important;'):'padding-top:0px !important;'"/>
      <!-- </keep-alive> -->
    </transition>

    <div v-if="appMode==='maintenance'" class="row justify-content-md-center m-1" :style="(!hideAppHeader)?((!loggedIn && appWidth<mobileWidth)?'padding-top:20px !important;':'padding-top:70px !important;'):'padding-top:0px !important;'">
      <div style="position:relative;" class="login-form col-12 col-md-8 text-center p-3 mb-3">
        <img class="w-100" :src="require('@/assets/_img/under_construction.png')" />
        <div style="position:absolute;width:95%;top:30%;color:white;font-size:2em;" v-html="this.maintenanceText"></div>
      </div>
    </div>

    <footer class="p-2 mt-0 footer" :title="'App version: v'+version">
      <div style="position: relative;float: left;" :class="(networkStatus==='offline')?'w-100 text-center':''" :style="(networkStatus==='offline')?'':''">
        <!-- <i class="fa fa-wifi" aria-hidden="true"></i> -->
        <div  v-if="networkStatus==='offline'" class="w-100" style="margin-top: -5px;">
          <span class="fa-stack">
            <i class="fas fa-wifi fa-stack-1x"></i>
            <i class="fas fa-slash fa-rotate-90 fa-stack-1x"></i>
          </span>
          <span>{{ $t('noInternetConnection') }}</span>
        </div>
        <!-- <i v-else class="fas fa-wifi"></i> -->
      </div>

      <div id="copyright" class="w-100 text-center" :style="(networkStatus==='offline')?'position:relative;float:left;':''">
        <!-- @click.stop="testAlert"> -->
        {{footerText}}{{(getSetting('years'))?getSetting('years'):''}}
        <a href="http://profel.si/" target="_blank" rel="noopener noreferrer">Prof.el</a>
        &amp; <a href="https://ribuild.si/" target="_blank" rel="noopener noreferrer">ri{build}</a>
      </div>
      <a v-if="getSetting('helpFile')" :href="getSetting('helpFile')" target="_blank" style="position:absolute;float:right;bottom:-3px;right:10px;width:30px;height:30px;color:gray;" :title="$t('userManual')">
        <i class="fa fa-question-circle" style="font-size: 1.5em;"></i>
      </a>
    </footer>

    <transition name="fade" mode="out-in">
      <app-modal :width="(appWidth>=mobileWidth)?'50%':'90%'" class="text-center" @closeModal="modal.show=false" v-show="modal.show">
        <div slot="title" class="fw800" style="font-size:1.5em;" :style="{ 'color': modal.color }">
          {{modal.type}}
        </div>
        <div v-if="modal.message.length>0" slot="content" class="text-center fw400 mt-3 mb-3 cl-black" style="font-size:1.2em;">
          <span v-if="modal.message!=='aaa'" v-html="modal.message"></span>
          <quill-editor class="w-100 my-2" v-else
            v-model="$store.state.messages[0].body"
            :options="editorOldOptions"
            :disabled="true"
          />
        </div>
        <div class="row" slot="buttons-right">
          <silver-button class="w-50 mx-auto" :caption="modal.buttonText" captionPosition="text-center" :disabled="false" @btnClick="modal.show=false /*closeModal*/" />
        </div>
      </app-modal>
    </transition>
    <!-- <button v-if="updateExists" style="position:fixed; bottom: 50px;left:10px;" @click="refreshApp">Update</button> -->
  </div>
</template>
<script>
import api from '@/backend-api'
import { mapState } from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// import menuX from '@/components/menuX.vue'
// import NProgress from 'nprogress'
import appModal from '@/components/modal.vue'
import pwaPrompt from '@/components/PWAPrompt.vue'
import updatePrompt from '@/components/updatePrompt.vue'
import menuLanguage from '@/components/menuLanguage.vue'
import silverButton from '@/components/silverButton.vue'
import update from './mixins/update'

export default {
  name: 'App',
  mixins: [update],
  components: {
    appModal,
    pwaPrompt,
    updatePrompt,
    menuLanguage,
    silverButton,
    quillEditor
  },
  data () {
    return {
      appMode: 'normal',
      maintenanceText: '',
      years: '2024',
      footerText: 'Moj Dialog App v2.0 ©',
      menuVisible: true,
      registration: null,
      updateExists: false,
      editorOldOptions: {
        // Some Quill options...
        theme: 'snow',
        modules: {
          registration: null,
          updateExists: false,

          refreshing: false,
          toolbar: false
        }
      }
    }
  },
  methods: {
    /*
    closeModal () {
      modal:Object
        buttonText:"OK"
        color:""
        message:""
        show:false
        type:""
    },
    */
    // showManual () {

    // },
    testAlert () {
      this.$swal.fire({
        title: 'Izbrišem datoteko?',
        text: 'Tega ne bo možno preklicati!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da, izbriši!',
        cancelButtonText: 'Prekliči'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Izbrisano!',
            'Datoteka je bila izbrisana.',
            'success'
          )
        }
      })
    },
    appUpdateUI (e) {
      console.log('Update UI')
      console.log(e)
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    cancelRefresh () {
      this.updateExists = false
    },
    getSetting (settingsName, field = 'value') {
      // console.log('GET SETTINGS', settingsName, this.appSettings)
      if (!this.appSettings) return null
      const sett = this.appSettings.find(el => el.name === settingsName)
      if (sett) return sett[field]
      return null
    },
    setAppWidth () {
      this.$store.dispatch('setAppWidth', { appWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) })
      // this.appWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      // console.log('BACKGROUND ' + this.appWidth)
      this.setAppBackground()
    },
    setAppBackground () {
      if (this.appWidth < this.mobileWidth) {
        document.body.style.background = 'url(' + this.domain + '/img/back_mobile.png) no-repeat center center fixed'
        // document.body.style.background = ''
        // document.body.style.backgroundColor = '#053c7c'
      } else {
        const backGround = this.getSetting('appBackground')

        if (backGround) {
          document.body.style.background = 'url(' + this.domain + backGround + ') no-repeat center center fixed'
          document.body.style.backgroundSize = 'cover'
        }
      }
    },
    getAppSettings (group, bind = null, name = null, lang = this.language) {
      const data = {
        group: group,
        bind: bind,
        name: name,
        jezik: lang
      }
      api.executePostServerAction('/data/getAppSettings', data, true)
        .then(response => {
          if (response.status === 200) {
            this.$store.state.app_settings = response.data.appSettings
          }
          if (response.data.error) {
            console.log('LOAD App settings inline error!')
            console.log('Error: ' + response.data.error)
            console.log(response.data)
          }
        })
        .catch(error => {
          console.log('LOAD App settings error!')
          console.log('Error: ' + error)
          console.log(error.response)
        })
        .finally(() => {
        })
    }
  },
  created () {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    window.addEventListener('resize', () => { this.setAppWidth() })
    this.getAppSettings('development')
    this.setAppWidth()
    // NProgress.configure({
    //   speed: 200,
    //   showSpinner: false
    // })
    // this.$router.beforeEach(() => {
    //   NProgress.start()
    // })
  },
  // mounted () {
  //   // console.log('window.location.origin', window.location.origin, this.domain)
  // },
  computed: {
    ...mapState({
      domain: state => state.domain,
      mobileWidth: state => state.app_mobile_width,
      appWidth: state => state.appWidth,
      language: state => state.language,
      loggedIn: state => state.loginSuccess,
      messages: state => state.messages,
      modal: state => state.modal,
      version: state => state.version,
      hideAppHeader: state => state.hideAppHeader,
      appSettings: state => state.app_settings
    }),
    networkStatus () {
      // if (this.networkStatus === 'offline') {
      this.$forceUpdate()
      return this.isOnline ? 'online' : 'offline'
    },
    currentPage () {
      return this.$route.path
    }
  },
  watch: {
    appSettings: {
      handler (val) {
        this.setAppBackground()
        this.appMode = this.getSetting('appMode')
        this.maintenanceText = this.getSetting('maintenanceText', 'text')
        this.footerText = this.getSetting('footerText', 'text')
        setTimeout(() => {
          this.getAppSettings('development')
        }, 30000)
      },
      deep: true
    }
    // modal: {
    //   handler (val) {
    //     if (val.show) {
    //       const audio = new Audio(require('@/assets/_sound/service-bell-ring.mp3'))
    //       const playPromise = audio.play()
    //       if (playPromise !== null) {
    //         playPromise.catch(() => { audio.play() })
    //       }
    //     }
    //   },
    //   deep: true
    // }
  }
}

/*
let w
let midX
let h
const particles = []

const Tau = Math.PI * 2
const ConnectionDist = 100
const maxParticles = 300
const radius = 3
const Msqrt = Math.sqrt
const Mrandom = Math.random

function handleResize () {
  w = this.ctx.canvas.width = window.innerWidth
  h = ctx.canvas.height = window.innerHeight
  midX = w * 0.5
}

function createParticles () {
  const vRange = 1.5
  const vMin = 0.5
  let vx, vy
  for (let i = 0; i < maxParticles; i++) {
    vx = Mrandom() * vRange + vMin
    vy = Mrandom() * vRange + vMin
    if (Mrandom() > 0.5) { vx *= -1 }
    if (Mrandom() > 0.5) { vy *= -1 }
    particles.push({
      x: Mrandom() * w - radius,
      y: Mrandom() * h - radius,
      xv: Mrandom() * vx,
      yv: Mrandom() * vy,
      strokeColour: { h: 0, s: 1 }
    })
  }
}

function update () {
  let p
  for (let loop = particles.length, i = 0; i < loop; i++) {
    p = particles[i]
    // move
    p.x += p.xv
    p.y += p.yv
    // keep in bounds
    if (p.x < 0) {
      p.x = 0
      p.xv *= -1
    } else if (p.x > w) {
      p.x = w
      p.xv *= -1
    }
    if (p.y < 0) {
      p.y = 0
      p.yv *= -1
    } else if (p.y > h) {
      p.y = h
      p.yv *= -1
    }
  }
}

function connect () {
  let p1, p2
  for (let i = 0; i < maxParticles - 1; i++) {
    p1 = particles[i]
    for (let j = i + 1; j < maxParticles; j++) {
      p2 = particles[j]
      const currentDist = getDistance(p2.x, p1.x, p2.y, p1.y)
      if (currentDist < ConnectionDist) {
        ctx.beginPath()
        ctx.moveTo(p1.x, p1.y)
        ctx.strokeStyle = 'hsla(' + p1.hue + ', 50%, 50%, ' + (1 - currentDist * 0.01) + ')'
        ctx.lineTo(p2.x, p2.y, p1.x, p1.y)
        ctx.stroke()
      }
    }
  }
}

function draw () {
  let p, d
  for (let loop = particles.length, i = 0; i < loop; i++) {
    p = particles[i]
    d = getDistance(midX, p.x, h, p.y)
    p.hue = d
    ctx.beginPath()
    ctx.fillStyle = 'hsla(' + d + ' , 50%, 30%, 1)'
    ctx.arc(p.x, p.y, radius, 0, Tau)
    ctx.fill()
  }
}

function getDistance (x1, x2, y1, y2) {
  const a = x1 - x2
  const b = y1 - y2
  return Msqrt(a * a + b * b)
}

function animate () {
  canvas.width = w
  update()
  connect()
  draw()
  requestAnimationFrame(animate)
}
*/
</script>
<stype scoped>
<
  @import "~nprogress/nprogress.css";
    #nprogress .bar{
    background: #57AD8D !important;
  }
</stype>
<style>
  @import url('https://fonts.googleapis.com/css?family=News+Cycle:400,700&subset=latin,latin-ext');
  @import url('https://fonts.googleapis.com/css?family=Cousine');
  :root {
    --grey: #2c3e50;
    --water-blue: #568FDD; /* #3a6c9b; */
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--grey);
  }
  body {
    font-family: 'News Cycle', sans-serif;
    line-height: 1.4;
    /* background: url(./assets/_img/ozadje.jpg) no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  footer {
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 15;
    border-bottom: solid 1px black;
    border-radius: 0 0 5px 5px;
  }
  nav {
    padding: 30px;
    /* box-shadow: 2px 0 10px black; */
    background-color: rgba(255, 255, 255, 0.9);
  }
  .centered-form {
    position: absolute; top: 50%; transform: translateY(-50%);
  }
  .details-data, .object-settings {
    float:left;background:rgba(255, 255, 255, 0.8);text-align:left;margin-top:0;
  }
  .fs06 {
    font-size: 0.6em;
  }
  .fs07 {
    font-size: 0.7em;
  }
  .fs08 {
    font-size: 0.8em;
  }
  .top-spinner {
    background:rgba(255, 255, 255, 0.8);text-align:center;float:left;padding:5px;
  }
  .spinner {
    width: 30px;
    position:absolute;z-index:10;padding-top:10px;
  }
  .bt-lgrey {
    border-top: solid 1px #d3d3d3;
  }
  .bb-lgrey {
    border-bottom: solid 1px #d3d3d3;
  }
  .p-absolute {
    position: absolute;
  }
  .p-relative {
    position: relative;
  }
  .in-line-dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .c-pointer {
    cursor: pointer;
  }
  .c-default {
    cursor: default;
  }
  .navbar {
    position: fixed !important;
    box-shadow: 0 2px 10px #0005;
    z-index: 10;
    top: 0;
    width: 100%;
    min-height: 65px;
  }
  .nav-dark {
    background-color: rgba(50, 79, 109, 0.9) !important;
  }
  footer {
    font-size: 12.8px;
    box-shadow: -2px 0 10px black;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .menu-ico, .menu-ico-logged {
    float: left;
    height: 42px;
    margin-top: 6px;
  }
  .menu-text, .menu-text-logged {
    font-size: 1.5em;
    font-weight: bold;
    color: #3876bf;
    position: relative;
    margin-top:15px;
    margin-left: 10px;
    /* text-shadow: 0 0 8px white; */
  }
  .main-menu {
    float: right;
    width: 40px;
    margin-top: 7px;
    cursor: pointer;
  }
  .opcx, .opcy {
    float: left;
    text-align: left;
    width: 95%;
    height: 32px;
    padding: 7px;
    border: #D3D3D3 solid 1px;
    border-bottom-color: rgb(211, 211, 211);
    font-size: 1.3em;
    color: #434343;
    margin-top: -1px;
    cursor: pointer;
    background-color: white;
    z-index: 1;
  }
/*  .opcx:hover, */ .opcy:hover, .opcz:hover, .opcxx:hover {
    box-shadow: 0 2px 15px #2B2B2B, 0 0 0 #2B2B2B, 0 0 0 #2B2B2B;
    z-index: 50;
  }
  .eqVnos {
    float: left;
    width: 30%;
  }
  .eqVnos input {
    /* width: 100%; */
    padding: 3px 10px;
    margin-top: -1px;
  }
  #filter {
    width: auto;
    padding: 6px 15px;
    height: 35px;
    text-align: center;
  }
  .add_icon {
    float: left;
    margin-top: -10px;
    margin-right: 5px;
    color: #FFAE00;
    font-style: italic;
    font-weight: bold;
    cursor: pointer;
    filter: drop-shadow(1px 1px 5px #222);
  }
  .filter_gray {
    filter: drop-shadow(1px 1px 5px #555);
  }
  .filter_red {
    filter: drop-shadow(1px 1px 5px red);
  }
  .filter_yellow {
    filter: drop-shadow(1px 1px 5px yellow);
  }
  .filter_green {
    filter: drop-shadow(1px 1px 5px green);
  }
  .filter_col_gray {
    color: #555;
  }
  .filter_col_red {
    color: red;
  }
  .filter_col_yellow {
    color: yellow;
  }
  .filter_col_green {
    color: #9de121;
  }

  .dropDown {
    position: absolute;
    font-size: 0.6em;
    width: 170px;
    height: auto;
    z-index: 20;
  }

  .opc {
    float: left;
    width: 45%;
    height: auto;
    padding: 7px;
    padding-right: 7px;
    padding-left: 7px;
    border: #D3D3D3 solid 1px;
    font-size: 1em;
    color: #434343;
    margin-top: -1px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
  }
  #izbor {
    top: 0px; left: 358px; font-size: 1em; width: 115px; text-align: center; background-color:white;
  }
  .filterReset {
    width: 38px !important;
    height: 38px !important;
    cursor: pointer !important;
    text-align: center !important;
    padding: 2px !important;
    margin-left: 10px !important;
    border: medium none !important;
    background-color: transparent !important;
  }
  .app-lang-list {
    position:absolute;
    float:left;
    width:145px;
    height:auto;
    top:0;
    right:0;
    z-index: 1;
    border: solid 1px grey;
    background: rgba(255,255,255,0.9);
    color:grey;
    text-align: left;
  }
  .app-flags {
    width:100%;font-weight:bold;
    cursor: pointer;
  }
  .app-flags-sel {
    width:100%;font-weight:bold;
    cursor: normal;
    background:lightblue;
    color:white;
  }
  .app-flags img, .app-flags-sel img {
    margin-left:5px;
  }
  .app-flags:hover {
    background:grey;color:white;
  }

  .pop-enter-active,  .pop-leave-active {
    transform-origin: center;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 2,275);
  }
  .pop-enter-from, .pop-leave-to {
    opacity: 0;
    transform: scale(1.3);
  }
  .slide-enter-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  @media only screen and (max-width: 1200px) { /* Extra large (xl) */
  }
  @media only screen and (max-width: 1024px) {
  }
  @media only screen and (max-width: 992px) { /* Large (lg) */
  }
  @media only screen and (max-width: 1024px) { /* Medium (md) */
    nav {
      background-color: rgba(50, 79, 109, 0.9);
      box-shadow: 2px 0 10px black;
      /* background-color: rgba(255, 255, 255, 0.9); */
    }
    .menu-ico {
      width:70px !important;
      float: unset;
      padding: 10px 14px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.9);
      margin-top: 20px;
      height: auto;
    }
    .menu-ico img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
    }
    .menu-text {
      width: 100%;
      font-size: 3em;
      margin-top: -20px;
      margin-left: 0;
      color: white;
    }
  }
  @media only screen and (max-width: 576px) { /* Small (sm) */
   /* Extra small (xs) */
  }
/*  @media only screen and (min-width: 576px) {
  }
*/
  @media only screen and (max-width: 476px) {
  }
  @media only screen and (max-width: 380px) {
  }
</style>
<i18n>
  {
    "sl": {
      "noInternetConnection": "Trenutno nimate internetne povezave.",
      "userManual":"Navodila za uporabo."
    },
    "en": {
      "noInternetConnection": "You currently have no internet connection.",
      "userManual":"Instructions for use."
    },
    "de": {
      "noInternetConnection": "Sie haben derzeit keine Internetverbindung.",
      "userManual":"Gebrauchsanweisung."
    }
  }
</i18n>
