<template>
  <div class="container">
    <div v-if="!loggedIn" class="col-12 p-0">
      <app-language :class="(loggedIn)?'logged':'not-logged'"/>
      <img v-if="pageIcon!=''" class="menu-ico" :src="require('@/assets/_img/'+pageIcon+'.png')" alt="" />
      <label class="menu-text float-left" :class="(appWidth<mobileWidth)?'mt-1':''">{{iconText}}</label>
    </div>
    <div v-else class="col-12 px-1 py-0">
<!--      <app-language :class="(loggedIn)?'logged':'not-logged'"/> -->
      <img v-if="pageIcon!=''" class="menu-ico-logged mr-2" :src="require('@/assets/_img/'+pageIcon+'.png')" alt="" />
      <label class="menu-text-logged float-left ml-0" :style="(appWidth<mobileWidth)?'color:white;margin-top:10px;':''">{{iconText}}</label>

      <img class="main-menu" :src="(appWidth<mobileWidth)?require('@/assets/_img/burger-menu-grey-40.png'):require('@/assets/_img/burger-menu-40.png')" @click="menuUserVisible=!menuUserVisible" />
      <!-- <transition name="fade" mode="out-in">
        <menu-x v-if="(appWidth<mobileWidth)?menuVisible:true" @select="menuVisible=false"/>
      </transition> -->
      <transition name="fade" mode="out-in">
        <menu-user v-if="(appWidth<mobileWidth)?menuUserVisible:true" @select="menuUserVisible=false" @mouseleave="menuUserVisible=false"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import store from '@/store'
// import menuX from '@/components/menuX.vue'
import menuUser from '@/components/menuUser.vue'
import appLanguage from '@/components/appLanguage.vue'

export default {
  name: 'menuLanguage',
  components: {
    // menuX,
    menuUser,
    appLanguage
  },
  data () {
    return {
      showLanguageSelect: false,
      // selectedLanguage: { name: 'Slovenščina', lang: 'sl', enabled: true },
      activeBackground: false,
      menuVisible: false,
      menuUserVisible: false
      /*
      menuOptions: [
        { name: this.$t('deqs'), route: '/deqs', level: 0, icon: 'DEQ', active: true },
        { name: this.$t('messages'), route: '/messages', level: 0, icon: 'chat', active: false },
        { name: this.$t('users'), route: '/users', level: 15, icon: 'users', active: false },
        { name: this.$t('designer'), route: '/scada', level: 15, icon: 'scadaDesigner', active: false },
        // { name: this.$t('settings'), route: '/settings', level: 1, icon: 'settings', active: false },
        // { name: this.$t('adminSett'), route: '/admin', level: 10, icon: 'admin', active: false },
        // { name: this.$t('logout'), route: '/', level: 0, icon: 'icon-off', active: false }
      ],
      languages: [
        { name: this.$t('slo'), lang: 'sl', code: 'slo', enabled: true },
        { name: this.$t('eng'), lang: 'en', code: 'eng', enabled: true },
        { name: this.$t('nem'), lang: 'de', code: 'nem', enabled: true },
        { name: this.$t('fra'), lang: 'fr', code: 'fra', enabled: false },
        { name: this.$t('ita'), lang: 'it', code: 'ita', enabled: false },
        { name: this.$t('bih'), lang: 'bh', code: 'bih', enabled: false }
      ]
      */
      // userLevels: [
      //   { level: 0, name: this.$t('guest') },
      //   { level: 1, name: this.$t('user') },
      //   { level: 2, name: this.$t('userPlus') },
      //   { level: 5, name: this.$t('service') },
      //   { level: 10, name: this.$t('admin') },
      //   { level: 15, name: this.$t('companyAdmin') },
      //   { level: 20, name: this.$t('superAdmin') }
      // ]
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState({
      mobileWidth: state => state.app_mobile_width,
      appWidth: state => state.appWidth,
      loggedIn: state => state.loginSuccess,
      pageIcon: state => state.pageIcon,
      iconText: state => state.iconText
    })
  }
}
</script>
<style>
</style>
<i18n>
  {
    "sl": {
      "deqs": "Moj Dialog",
      "messages": "Sporočila",
      "users": "Uporabniki",
      "designer": "SCADA designer",
      "settings": "Nastavitve",
      "adminSett": "Admin",
      "logout": "Odjava",
      "guest": "Gost",
      "user": "Uporabnik",
      "userPlus": "Uporabnik+",
      "service": "Serviser",
      "admin": "Admin",
      "companyAdmin": "Company admin",
      "superAdmin": "Super admin",

      "slo": "Slovenščina",
      "eng": "English",
      "nem": "Deutsch",
      "fra": "Français",
      "ita": "Italiano",
      "bih": "Bosanščina"
    },
    "en": {
      "deqs": "My Dialog",
      "messages": "Messages",
      "users": "Users",
      "designer": "SCADA designer",
      "settings": "Settings",
      "adminSett": "Admin",
      "logout": "Logout",
      "guest": "Guest",
      "user": "User",
      "userPlus": "User+",
      "service": "Maintenance",
      "admin": "Admin",
      "companyAdmin": "Company admin",
      "superAdmin": "Super admin",

      "slo": "Slovenščina",
      "eng": "English",
      "nem": "Deutsch",
      "fra": "Français",
      "ita": "Italiano",
      "bih": "Bosanščina"
    },
    "de": {
      "deqs": "Meine Dialog",
      "messages": "Meldungen",
      "users": "Benutzer",
      "designer": "SCADA designer",
      "settings": "Einstellungen",
      "adminSett": "Admin",
      "logout": "Abmelden",
      "guest": "Gast",
      "user": "Benutzer",
      "userPlus": "Benutzer+",
      "service": "Betreuer",
      "admin": "Admin",
      "companyAdmin": "Company admin",
      "superAdmin": "Super admin",

      "slo": "Slovenščina",
      "eng": "English",
      "nem": "Deutsch",
      "fra": "Français",
      "ita": "Italiano",
      "bih": "Bosanščina"
    }
  }
</i18n>
