<template>
  <div class="menuX dropDown" @mouseleave="$emit('select')">
    <div class="opcx glava" style="width:auto;">
      <app-language style="float:left;width:60px;position:relative;padding-top:5px;" />
      <!-- <menu-language /> -->
      <div class="user-data" @click="toggleSubMenu">
        <div class="userNapis">{{userName}}</div>
        <div class="userNapis" style="margin-top:-6px;">
          <small>{{level.name}}</small>
        </div>
      </div>
      <div v-if="(appWidth>=mobileWidth)" class="toggle-arrow" style="float:right;width:15px;cursor:pointer;" @click="toggleSubMenu">
        <i class="fas fa-angle-left arrow-icon" :class="(openSub)?'rotated':''"></i>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="openSub">
        <div :key="key" v-for="(option, key) in userMenuOptions" class="opcx" :class="($route.path.toLowerCase()==option.route)?'izb':''" @click="$emit('select');routeClick(option.route)">
          <img class="optIco" :src="require('@/assets/_img/'+option.icon+'.png')">{{option.text}}
          <span class="float-right" v-if="$route.path.toLowerCase()==option.route"><img src="@/assets/_img/tick.png" class="menuX_tick"/></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import store from '../store'
import appLanguage from '@/components/appLanguage.vue'

export default {
  name: 'menuUser',
  components: {
    appLanguage
  },
  props: {
    open: Boolean
  },
  data () {
    return {
      openSub: false,
      menuVisible: true,
      menuOptions: [
        { route: '/objects', level: 0, icon: 'DEQ', text: this.$t('objects') },
        { route: '/deqs', level: 5, icon: 'controller', text: this.$t('deqs') },
        { route: '/users', level: 5, icon: 'users', text: this.$t('users') },

        { route: '/messages', level: 0, icon: 'chat', text: this.$t('messages') },
        { route: '/settings', level: 1, icon: 'settings', text: this.$t('settings') },
        { route: '/admin', level: 20, icon: 'admin', text: this.$t('adminSett') },

        { route: '/companies', level: 20, icon: 'company', text: this.$t('companies') },
        { route: '/scada', level: 20, icon: 'scada', text: this.$t('designer') },
        { route: '/info', level: 0, icon: 'info-info', text: this.$t('info') },
        { route: '/', level: 0, icon: 'icon-off', text: this.$t('logout') }
      ],
      userLevels: [
        { level: 0, name: this.$t('guest') },
        { level: 1, name: this.$t('user') },
        { level: 2, name: this.$t('userPlus') },
        { level: 5, name: this.$t('service') },
        { level: 10, name: this.$t('admin') },
        { level: 15, name: this.$t('companyAdmin') },
        { level: 20, name: this.$t('superAdmin') }
      ]
    }
  },
  methods: {
    toggleSubMenu () {
      if (this.appWidth < this.mobileWidth) return
      this.openSub = !this.openSub
    },
    routeClick (route) {
      this.hideMenu()
      if (route === this.$route.path) return false
      if (route === '/') {
        this.logOut()
        return false
      }
      if (this.$route.path.toLowerCase() === route) return
      this.$forceUpdate()
      this.$router.push(route)
    },
    hideMenu () {
      this.toggleSubMenu()
      // this.$emit('select')
    },
    logOut () {
      // console.log('LOGOUT')
      this.$store.dispatch('logout')
      this.$store.dispatch('openPage', { pageIcon: 'DEQ', iconText: 'Moj Dialog' })

      this.$router.push('/login')
      this.$emit('select')
    }
  },
  mounted () {
    if (this.appWidth < this.mobileWidth) this.openSub = true
  },
  computed: {
    ...mapState({
      mobileWidth: state => state.app_mobile_width,
      appWidth: state => state.appWidth,
      loggedIn: state => state.loginSuccess,
      userName: state => (state.name) ? state.name : state.userName,
      userLevel: state => state.nivo
    }),
    level () {
      return this.userLevels.find(level => level.level === parseInt(this.userLevel))
    },
    userMenuOptions () {
      return this.menuOptions.filter(mo => mo.level <= this.userLevel)
    }
  },
  watch: {
    open (val) {
      console.log('changed -> ' + val)
      this.menuVisible = val
    },
    appWidth (val) {
      this.openSub = (val < this.mobileWidth)
      this.menuVisible = (val >= this.mobileWidth)
    }
  }
}
</script>
<style>
  /*
  .menuX .app-language {
    ! right: 45px; */ /*! top: 14px;
    position: relative !important;
    float: left !important;
    margin-left: 5px !important;
    margin-top: 6px !important;
  }
  */
  .opcx, .opcy {
    float: left;
    text-align: left;
    width: 95%;
    height: 32px;
    padding: 7px;
    border: #D3D3D3 solid 1px;
    border-bottom-color: rgb(211, 211, 211);
    font-size: 1.3em;
    color: #434343;
    margin-top: -1px;
    cursor: pointer;
    background-color: white;
    z-index: 150;
  }
/*  .opcx:hover, */ .opcy:hover, .opcz:hover, .opcxx:hover {
    box-shadow: 0 2px 15px #2B2B2B, 0 0 0 #2B2B2B, 0 0 0 #2B2B2B;
    z-index: 50;
  }
</style>
<style scoped>
  img {
    max-width: 100%;
    border: 0;
    vertical-align: middle;
  }
  label {
    position: relative;
    vertical-align: bottom;
    bottom: 3px;
  }
  .menuX {
    display: block;
    height: 60px;
    top: 7px;
    right: -12px;
    z-index: 100;
    position: absolute;
    font-size: 0.6em;
    width: 240px;
  }
  .user-data {
    float: left; position: relative; width: 150px; height: 119%;border-left:solid 1px white;
  }
  .glava {
    position: relative;
    font-size:1.7em !important;
    font-weight: bold;
    text-align: center !important;
    cursor: default !important;
    border-bottom: 2px solid darkgreen !important;
    height: 44px !important;
    padding-top: 0px !important;
    color: white !important;
    background-color: #578BC8 !important;
    width:300px;
  }
  .toggle-arrow {
    cursor:pointer;position:absolute; top:0; right:5px;padding: 9px 5px; width:15px; height:100%; border-left: solid 1px #D3D3D3;z-index:30;
  }
  .arrow-icon {
    transform: rotate(0deg);
    transition: transform 0.3s ease-out;
  }
  .rotated {
    transform: rotate(-90deg);
    transition: transform 0.3s ease-out;
  }
  .optIco {
    width:20px;
    margin-right:10px;
    margin-left:10px;
    margin-top: -5px;
  }
  .userNapis {
    float: left;
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .menuX_tick {
    height: 16px;
    margin-left: 5px;
    margin-top: -2px;
  }
  .izb {
    cursor: default !important;
    background-color: lavender !important;
  }
</style>
<i18n>
  {
    "sl": {
      "deqs": "Krmilniki",
      "objects": "Objekti",
      "messages": "Sporočila",
      "companies": "Podjetja",
      "users": "Uporabniki",
      "designer": "SCADA designer",
      "settings": "Nastavitve",
      "adminSett": "Admin",
      "logout": "Odjava",
      "info": "Info",

      "guest": "Gost",
      "user": "Uporabnik",
      "userPlus": "Uporabnik+",
      "service": "Serviser",
      "admin": "Admin",
      "companyAdmin": "Company admin",
      "superAdmin": "Super admin"
    },
    "en": {
      "deqs": "Controllers",
      "objects": "Objects",
      "messages": "Messages",
      "companies": "Companies",
      "users": "Users",
      "designer": "SCADA designer",
      "settings": "Settings",
      "adminSett": "Admin",
      "logout": "Logout",
      "info": "Info",

      "guest": "Guest",
      "user": "User",
      "userPlus": "User+",
      "service": "Maintenance",
      "admin": "Admin",
      "companyAdmin": "Company admin",
      "superAdmin": "Super admin"
    },
    "de": {
      "deqs": "Regler",
      "objects": "Controller",
      "messages": "Meldungen",
      "companies": "Unternehmen",
      "users": "Benutzer",
      "designer": "SCADA designer",
      "settings": "Einstellungen",
      "adminSett": "Admin",
      "logout": "Abmelden",
      "info": "Info",

      "guest": "Gast",
      "user": "Benutzer",
      "userPlus": "Benutzer+",
      "service": "Betreuer",
      "admin": "Admin",
      "companyAdmin": "Company admin",
      "superAdmin": "Super admin"
    }
  }
</i18n>
