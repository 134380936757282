import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import api from '@/backend-api'

Vue.use(Vuex)

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
} else {
  Vue.config.devtools = true
}

export default new Vuex.Store({
  state: {
    api: process.VUE_APP_API,
    domain: process.env.VUE_APP_DOMAIN,
    version: process.env.VUE_APP_VERSION,
    app_root: process.env.VUE_APP_APP_ROOT,
    app_mobile_width: parseInt(process.env.VUE_APP_MOBILE_WIDTH),
    app_settings: null,
    language: 'slo',
    appWidth: 1200,
    hideAppHeader: false,
    slidingList: false,
    loading: false,
    pageIcon: '',
    iconText: '',
    loginSuccess: false,
    loginError: false,
    userName: null,
    userPass: null,
    token: null,
    name: null,
    uid: null,
    nivo: null,
    readonly: 0,
    lastObject: null,
    objects: [],
    messages: [],
    modal: {
      show: false,
      message: '',
      color: '',
      type: '',
      buttonText: 'OK',
      messages: [],
      msgCode: null
    },
    deqs_lastlog: []
  },
  mutations: {
    login_success (state, payload) {
      state.loginSuccess = true
      state.userName = payload.userName
      state.userPass = payload.userPass
      state.token = payload.token
      state.name = payload.name
      state.uid = payload.uid
      state.nivo = payload.nivo
      state.readonly = payload.readonly
      state.hideAppHeader = false
      state.lastObject = payload.lastObject
      state.messages = payload.messages
      state.objects = payload.objects
    },
    login_error (state, payload) {
      // console.log('PAYLOAD', payload)
      state.loginError = true
      state.userName = payload.userName
      state.token = null
      state.name = null
      state.uid = null
      state.nivo = null
      state.readonly = 0
      state.hideAppHeader = false
      state.lastObject = null
      state.objects = null
      state.messages = []
      state.modal.msgCode = payload.error
    },
    logout (state) {
      state.loginSuccess = false
      state.loginError = false
      state.userName = null
      state.userPass = null
      state.token = null
      state.name = null
      state.uid = null
      state.nivo = null
      state.readonly = 0
      state.hideAppHeader = false
      state.messages = []
      state.objects = null
      localStorage.removeItem('storedUser')
      localStorage.removeItem('storedPass')
      state.loginSuccess = false
      state.modal.msgCode = null
    },
    setPage (state, payload) {
      state.pageIcon = payload.pageIcon
      state.iconText = payload.iconText
    },
    set_AppWidth (state, payload) {
      state.appWidth = payload.appWidth
    },
    set_deqsLastLog (state, payload) {
      state.deqs_lastlog = payload.deqs_lastLog
    },
    set_lastObject (state, payload) {
      state.lastObject = payload.selObjectId
    },
    set_loading (state, payload) {
      state.loading = payload
    },
    set_language (state, payload) {
      state.language = payload
    },
    set_slidingList (state, payload) {
      state.slidingList = payload
    },
    set_hideAppHeader (state, payload) {
      state.hideAppHeader = payload
    }
  },
  actions: {
    openPage ({ commit }, { pageIcon, iconText }) {
      commit('setPage', {
        pageIcon: pageIcon,
        iconText: iconText
      })
    },
    setAppWidth ({ commit }, { appWidth }) {
      commit('set_AppWidth', {
        appWidth: appWidth
      })
    },
    setHideAppHeader ({ commit }, headerState) {
      commit('set_hideAppHeader', headerState)
    },
    set_deq_lastlog ({ commit }, { lastLog }) {
      commit('set_deqsLastLog', {
        deqs_lastLog: lastLog
      })
    },
    checkToken ({ commit }) {
      // console.log('LOGOUT!!!')
      return new Promise((resolve, reject) => {
        api.executePostServerAction('/auth/checkToken', {}, true)
          .then(response => { // eslint-disable-line
            // console.log('Response: ')
            // console.log(response.data)
            if (response.status === 200) {
              // Prijava je uspela
              // this.state.loginSuccess = true
              // commit('logout')
              resolve(response)
            }
          })
          .catch(error => {
            // console.log('NAPAKA!!!')
            // console.log(error) // eslint-disable-line
            // commit('login_error', {})
            reject('Napaka pri preverjanju Tokna!', error) // eslint-disable-line
          })
      })
    },
    logout ({ commit }) {
      // console.log('LOGOUT!!!')
      return new Promise((resolve, reject) => {
        api.executePostServerAction('/auth/logout', {}, true)
          .then(response => { // eslint-disable-line
            // console.log('Response: ')
            // console.log(response.data)
            if (response.status === 200) {
              // Prijava je uspela
              commit('logout')
              resolve(response)
            }
          })
          .catch(error => {
            // console.log('NAPAKA!!!')
            // console.log(error) // eslint-disable-line
            // commit('login_error', {})
            reject('Napaka pri odjavi!', error) // eslint-disable-line
          })
      })
    },
    login ({ commit }, { userName, userPass, language }) {
      return new Promise((resolve, reject) => {
        // console.log('LOGINN!!!!')
        const loginData = {
          loginUser: userName,
          loginGeslo: userPass,
          language: language
        }
        // api.executePostServerAction('/auth/login', { data: Buffer.from(JSON.stringify(loginData)).toString('Base64') })
        // api.executePostServerAction('/auth/login', { data: Buffer.from(Buffer.from(JSON.stringify(loginData)).toString('Base64')).toString('Base64') })
        api.executePostServerAction('/auth/login', { data: Buffer.from(JSON.stringify(loginData)).toString('Base64') })
          .then(response => {
            // console.log('Response: ', response)
            const data = response.data.data
            // console.log(data)
            if (response.status === 200) {
              // Prijava je uspela
              // console.log(JSON.parse(atob(data.token)))
              commit('login_success', {
                userName: userName,
                userPass: userPass,
                token: data.token,
                name: data.ime,
                uid: parseInt(data.uid),
                nivo: data.nivo,
                readonly: data.readonly,
                lastObject: data.lastObject,
                objects: (data.objects) ? data.objects : [],
                messages: (data.messages) ? data.messages : []
              })
              resolve(response)
            }
            // if (response.data.error === true) {
            //   reject(new Error('Login failed!'))
            //   return false
            // }
            if (response.status >= 400) {
              // Prijava ni uspela
              // console.log('LOGIN FAILED', response)
              commit('login_error', {
                modal: response.msgCode,
                error: response.data.msgCode,
                userName: userName
              })
              if (response.status >= 500) {
                reject('Server error!') // eslint-disable-line
              } else {
                reject('Napačno uporabniško ime ali geslo!') // eslint-disable-line
              }
            }
          })
          .catch(error => {
            // console.log('Response: ', response)
            // console.log('ERROR NOT LOGGED IN!!!', error.response.data.msgCode)

            commit('login_error', {
              modal: error.response.data.msgCode,
              error: error.response.data.msgCode,
              userName: userName
            })
            reject('Server error!') // eslint-disable-line
          })
        /*
        api.getSecured(user, password)
          .then(response => {
            // console.log("Response: '" + response.data + "' with Statuscode " + response.status);
            if (response.status === 200) {
              console.log('Login successful');
              // place the loginSuccess state into our vuex store
              commit('login_success', {
                userName: user,
                userPass: password
              });
            }
            resolve(response)
          })
          .catch(error => {
            console.log("Error: " + error);
            // place the loginError state into our vuex store
            commit('login_error', {
              userName: user
            });
            reject("Invalid credentials!")
          })
*/
      })
    }
  },
  getters: {
    isLoggedIn: state => state.loginSuccess,
    hasLoginErrored: state => state.loginError,
    getName: state => state.name,
    getUserName: state => state.userName,
    getUserPass: state => state.userPass,
    getUserLevel: state => state.nivo,
    getUserLastObject: state => state.lastObject,
    getUserObjects: state => state.objects,
    isAdmin: state => state.admin,
    isServis: state => state.servis,
    pageIcon: state => state.pageIcon,
    iconText: state => state.iconText,
    loading: state => state.loading,
    deqs_lastlog: state => state.deqs_lastlog,

    slidingList: state => state.slidingList,
    token: state => state.token
  }
})
