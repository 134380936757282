<template>
  <div class="pwa-prompt w-100 p-1" v-if="shown">
    <div class="pwa-text" :class="(appWidth<mobileWidth)?'text-center w-100':''">
      <i class="fa fa-arrow-circle-down mr-1" style="font-size:30px;"></i>
    </div>
    <div class="pwa-text pt-1" :class="(appWidth<mobileWidth)?'mb-2':''">
      {{$t('installApp')}}
    </div>
    <silver-button borderColor="green" class="mx-1 mt-0" :class="(appWidth<mobileWidth)?'float-right':'w-25'" :caption="$t('install')" captionPosition="text-center" :disabled="false" @btnClick="installPWA" />
    <silver-button class="mt-0" :class="(appWidth<mobileWidth)?'float-left':'w-25'" :caption="$t('noInstall')" captionPosition="text-center" :disabled="false" @btnClick="dismissPrompt" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import silverButton from '@/components/silverButton.vue'
export default {
  name: 'PWA-prompt',
  components: {
    silverButton
  },
  data () {
    return {
      shown: false,
      installEvent: null
    }
  },

  beforeMount () {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    installPWA () {
      if (this.installEvent) {
        this.installEvent.prompt()
        this.installEvent.userChoice.then((choice) => {
          this.dismissPrompt() // Hide the prompt once the user's clicked
          if (choice.outcome === 'accepted') {
            // Do something additional if the user chose to install
          } else {
            // Do something additional if the user declined
          }
        })
      }
    },
    dismissPrompt () {
      this.shown = false
    }
  },
  computed: {
    ...mapState({
      mobileWidth: state => state.app_mobile_width,
      appWidth: state => state.appWidth
    })
  }
}
</script>
<style>
  .pwa-prompt {
    z-index: 15;
/*     border: grey; */
    position: fixed;
    background: white;
    font-weight: bold;
    border-bottom: solid 1px #d3d3d3;
  }
  .pwa-text {
    margin-top:3px;float:left; margin-left: 10px;
  }
</style>
<i18n>
  {
    "sl": {
      "installApp": "Namestim Dialog Web App na domač zaslon?",
      "install": "Namesti!",
      "noInstall": "Ne, hvala"
    },
    "en": {
      "installApp": "Add app to home screen?",
      "install": "Install!",
      "noInstall": "No, thanks"
    },
    "de": {
      "installApp": "App zum Startbildschirm hinzufügen?",
      "install": "Installieren!",
      "noInstall": "Nein, Danke"
    }
  }
</i18n>
