<template>
  <div class="app-language">
    <img class="mr-2" style="width:32px;cursor:pointer;" :src="require('@/assets/_img/flag'+selectedLanguage.lang+'.png')" alt="flag" @click="showLanguageSelect=!showLanguageSelect" />
    <div v-if="showLanguageSelect" class="app-lang-list" @mouseleave="showLanguageSelect=false">
      <div
        :class="(selectedLanguage.lang!=flag.lang)?'app-flags':'app-flags-sel'"
        :key="key"
        v-for="(flag,key) in enabledLanguages"
        @click="selectLanguage(flag)"
      >
        <img
          :src="require('@/assets/_img/flag'+flag.lang+'.png')"
          style="margin-right:15px;"
          :title="flag.name"
          alt=""
        />
        <span style="margin-top:2px">{{flag.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'appLanguage',
  props: {
  },
  data () {
    return {
      showLanguageSelect: false,
      selectedLanguage: { name: 'Slovenščina', lang: 'sl', code: 'slo', enabled: true },
      languages: [
        { name: this.$t('slo'), lang: 'sl', code: 'slo', enabled: true },
        { name: this.$t('eng'), lang: 'en', code: 'eng', enabled: true },
        { name: this.$t('nem'), lang: 'de', code: 'nem', enabled: true },
        { name: this.$t('fra'), lang: 'fr', code: 'fra', enabled: false },
        { name: this.$t('ita'), lang: 'it', code: 'ita', enabled: false },
        { name: this.$t('bih'), lang: 'bh', code: 'bih', enabled: false }
      ]
    }
  },
  methods: {
    selectLanguage (lang, reload = true) {
      if (lang.lang === this.selectedLanguage.lang) {
        this.$store.commit('set_language', this.selectedLanguage.code)
        this.showLanguageSelect = false
        return false
      }
      this.selectedLanguage = lang
      this.showLanguageSelect = false
      this.$i18n.locale = lang.lang
      localStorage.setItem('locale', lang.lang)
      this.$store.commit('set_language', lang.code)
      if (reload) {
        // localStorage.setItem('storedRoute', this.$route.path)
        this.$router.go()
      }
    }
  },
  mounted () {
  },
  created () {
    const lang = this.languages.find(el => el.lang === this.$i18n.locale)
    if (lang) this.selectLanguage(lang, false)
  },
  computed: {
    ...mapState({
      appWidth: state => state.appWidth,
      loggedIn: state => state.loginSuccess,
      language: state => state.language
    }),
    enabledLanguages () {
      return this.languages.filter(flag => flag.enabled)
    }
  }
}
</script>
<style>
  .logged {
    position:absolute;
    right:45px;top:14px;
  }
  .not-logged {
    position:absolute;
    right:10px; top:10px;
  }
</style>
<i18n>
  {
    "sl": {
      "slo": "Slovenščina",
      "eng": "English",
      "nem": "Deutsch",
      "fra": "Français",
      "ita": "Italiano",
      "bih": "Bosanščina"
    },
    "en": {
      "slo": "Slovenščina",
      "eng": "English",
      "nem": "Deutsch",
      "fra": "Français",
      "ita": "Italiano",
      "bih": "Bosanščina"
    },
    "de": {
      "slo": "Slovenščina",
      "eng": "English",
      "nem": "Deutsch",
      "fra": "Français",
      "ita": "Italiano",
      "bih": "Bosanščina"
    }
  }
</i18n>
